var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "item-main",
      class: { "highlight-border-main": _vm.focusInput },
      on: {
        click: function ($event) {
          return _vm.$refs.comment.focus()
        },
      },
    },
    [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "approval-main" },
        [
          _c("el-input", {
            ref: "comment",
            staticClass: "comment",
            attrs: {
              type: "textarea",
              maxlength: "500",
              placeholder: "请输入审批意见",
            },
            on: {
              focus: function ($event) {
                _vm.focusInput = true
              },
              blur: function ($event) {
                _vm.focusInput = false
              },
            },
            model: {
              value: _vm.businessData.mdInfo.approvalComment,
              callback: function ($$v) {
                _vm.$set(_vm.businessData.mdInfo, "approvalComment", $$v)
              },
              expression: "businessData.mdInfo.approvalComment",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "name" }, [
      _c("span", { staticClass: "title" }, [_vm._v("审批意见")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
<!--
 * @Description: 审批概要
 * @Author: 梁平贤
 * @Date: 2020/7/4 16:58
 -->
<template>
  <div class="item-main" :class="{'highlight-border-main':focusInput}" @click="$refs.comment.focus()">
    <div class="name">
      <span class="title">审批意见</span>
    </div>
    <div class="approval-main">
      <el-input ref="comment"
                class="comment"
                type="textarea"
                maxlength="500"
                placeholder="请输入审批意见"
                v-model="businessData.mdInfo.approvalComment"
      @focus="focusInput=true"
      @blur="focusInput=false"></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "BMApprovalCommentColumn",
  props: {
    column: {
      type: Object,
      require: true,
      default() {
        return {};
      }
    },
    // 可能需要的整体数据
    businessData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      focusInput: false,
      hoverInput: false
    };
  }
};
</script>

<style scoped lang="scss">
  .item-main {
    width: 100%;
    border: 1px solid #e8ecf2;
    border-radius: 4px;
    margin: 0;
    &:hover{
      border-color: #a2cdf7;
    }
    &.highlight-border-main{
      border-color: #3e90fe;
    }
    .name {
      font-weight: bold;
      border-bottom: 1px solid #e8ecf2;
      background-color: #f5f7fa;
      height: 48px;
      line-height: 48px;
      padding-left: 10px;
      text-align: left;
      .title {
        vertical-align: middle;
      }
    }
    .approval-main {
      line-height: 0;
      .comment {
       & /deep/ .el-textarea__inner {
          border: 0;
        }
      }

    }
  }

</style>
